import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LocalizationStateProvider } from './context/useLocalizationState';
import { ThemeStateProvider } from './context/useThemeState';
import { GlobalStateProvider } from './context/useGlobalState';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <LocalizationStateProvider>
    <ThemeStateProvider>
      <GlobalStateProvider>
        <App />
      </GlobalStateProvider>
    </ThemeStateProvider>
  </LocalizationStateProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
